import React from 'react';
import TrackingPopUp from './Components/TrackingPopUp/TrackingPopUp';
import GithubLogo from './resources/images/github-logo.png';
import './App.css';

function App() {
    return (
        <div className='App'>
            <div className='dz-title'>
                <div className='bracket'>{`{`}</div>
                <div className='main'>DZ</div>
                <div className='bracket'>{`}`}</div>
            </div>
            <a href='https://www.github.com/dmitriyzuchko'>
                <img src={GithubLogo} alt='' />
            </a>
            <TrackingPopUp trackingID='UA-43864131-1' />
        </div>
    );
}

export default App;
